<template>
  <div class="header"  >
    <div class="head">
      <div class="logo"><img src="../src/assets/img/logo.png" alt=""></div>
      <nav class="header_nav">
        <li><router-link to="/">首页</router-link></li>
        <li class="x_nav">
          <div >网络游戏<span class="button-down"></span></div>
          <div class="pop-up">
              <div class="pop-up-content">
                <div class="second_level">
                  <ul class="second_level_list">
                    <li>
                       <router-link to="/NetworkPublishing">网络游戏</router-link>
                    </li>
                    <li>
                    <router-link to="/Music_art">网络动漫</router-link>
                    </li>
					<li>
					<router-link to="/BlockChain">展会活动</router-link>
					</li>
                  </ul>
                </div>
              </div>
          </div>
        </li>
        <li><router-link to="/news_more">廉政建设</router-link>
        </li>
        <li><router-link to="/information">信息公开</router-link>
          
        </li>

        <li><router-link to="/AboutUs">关于我们</router-link></li>
      </nav>
      <div class=""><img src="" alt=""></div>
    </div>
  </div>
  <router-view/>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  methods(){

  },
};
</script>
<style scoped>
@import '../src/assets/css/header.css';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.pop-up{
}
body,html{
	font-family: "Microsoft YaHei", Arial, sans-serif;
}
</style>
